import classnames from 'classnames';
import React from 'react';
import injectStyles from 'react-jss';
import { withRouter } from 'react-router-dom';
import { InternalLinkButton } from '../../styledElements/LinkButton';
import MobileMenu from './MobileMenu';

const styles = ({ brakePoint: { mobile } }) => ({
  root: {
    display: 'flex',
    top: 'calc(50% - 8px)',
    zIndex: 1000,
    pointerEvents: 'none',
  },
  inlineContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    paddingLeft: '5px',
    position: 'relative',
  },
  arrow: {
    height: '9px',
  },
  arrowRoot: {
    position: 'absolute',
    top: '1px',
    left: '-1px',
  },
  column: {
    alignItems: 'flex-end',
    textAlign: 'right',
    minWidth: '150px',
    flexFlow: 'column',
    position: 'relative',
    margin: '20px 20px 10px 6px',
    left: 'auto',
  },
  row: {
    flexFlow: 'row',
  },
  mobile: {
    display: 'none',
    [mobile]: {
      display: 'block',
    },
  },
  desktop: {
    display: 'block',
    [mobile]: {
      display: 'none',
    },
  },
});

const menu = [
  { name: 'Festiwal', link: '/festiwal' },
  { name: 'O nas', link: '/o-nas' },
  { name: 'Program', link: '/program' },
  { name: 'Wolontariat', link: '/wolontariat' },
  { name: 'Praktyczne informacje', link: '/praktyczne-informacje' },
  { name: 'Mapa rzemieślników', link: '/mapa' },
  // { name: 'Wesprzyj nas', link: '/wesprzyj-nas' },
  { name: 'Kontakt', link: '/kontakt' },
];

const MainMenu = ({ classes, column, location: { pathname } }) => {
  const tab = `/${pathname.split('/')[1]}`;
  return (
    <>
      <div className={classes.mobile}>
        <MobileMenu column={column} menu={menu} pathname={tab} />
      </div>
      <div className={classes.desktop}>
        <div className={classnames(classes.root, column ? classes.column : classes.row)}>
          {menu.map(menuItem => (
            <div className={classes.inlineContainer} key={menuItem.name}>
              <InternalLinkButton
                active={menuItem.link === tab}
                to={menuItem.link}
                alt={menuItem.name}
                style={{ color: 'black', fontSize: 14 }}
              >
                {menuItem.name}
              </InternalLinkButton>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default withRouter(injectStyles(styles)(MainMenu));
