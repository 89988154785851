import bg from '../../../assets/images/hp/bg.png';
import plakatMain from '../../../assets/images/hp/plakatMain.gif';
import plakat2img from '../../../assets/images/hp/plakat2.gif';
import PIERWSZY_WIDOK_LEWA from '../../../assets/images/hp/PIERWSZY_WIDOK_LEWA.gif';
import PIERWSZY_WIDOK_PRAWA from '../../../assets/images/hp/PIERWSZY_WIDOK_PRAWA.gif';
import DRUGI_WIDOK_LEWA from '../../../assets/images/hp/DRUGI_WIDOK_LEWA.gif';
import DRUGI_WIDOK_PRAWA from '../../../assets/images/hp/DRUGI_WIDOK_PRAWA.gif';
import TRZECI_WIDOK_LEWA from '../../../assets/images/hp/TRZECI_WIDOK_LEWA.gif';
import TRZECI_WIDOK_PRAWA from '../../../assets/images/hp/TRZECI_WIDOK_PRAWA.gif';


const data = {
  desktop: {
    right: [
      {
        x: 3.2, y: 0.9, z: 0.5, scale: 2.5, ratio: 1, url: PIERWSZY_WIDOK_PRAWA,
      },
      {
        x: 3.2, y: 0.5, z: 0.5, scale: 2.5, ratio: 1, url: DRUGI_WIDOK_PRAWA,
      },
      {
        x: 3.2, y: 0.5, z: 0.5, scale: 2.5, ratio: 1, url: TRZECI_WIDOK_PRAWA,
      },
    ],
    left: [
      {
        x: -2.8, y: 0.2, z: 0.7, scale: 2.2, ratio: 1.1, url: PIERWSZY_WIDOK_LEWA,
      },
      {
        x: -2.5, y: 0.9, z: 0.7, scale: 2.2, ratio: 1.1, url: DRUGI_WIDOK_LEWA,
      },
      {
        x: -2.5, y: 0.9, z: 0.7, scale: 2.2, ratio: 1.1, url: TRZECI_WIDOK_LEWA,
      },
    ],
    background: [
      {
        x: -1.6, y: -1.2, z: -0.7, scale: 3.2, ratio: 1.2, url: bg,
      },
      {
        x: 2.2, y: 0, z: -1.5, scale: 3.5, ratio: 1, url: bg,
      },
    ],
    center: [
      {
        x: 0, y: 0.3, z: 0, scale: 6.5, ratio: 1, url: plakatMain,
      },
      {
        x: 0, y: 0, z: 0, scale: 7.3, ratio: 1, url: plakat2img,
      },
    ],
    text: [
      {
        x: 0, y: 0.3, z: 0, scale: 5, ratio: 0.75516224,
        text: 'Słysząc słowo DOM zwracamy się przede wszystkim ku swojemu domowi, mieszkaniu. A gdyby tak spojrzeć szerzej i za nasze DOMy uznać, jak to się czasem mówi - cały świat? Więcej informacji w zakładce Program. ',
        items: [
          'kontakt@fomkrakow.pl\n+48 733 779 907',
        ],
      },
    ],
  },
  mobile: {
    right: [
      {
        x: 0.5, y: 1.1, z: -0.5, scale: 3.7, ratio: 1, url: PIERWSZY_WIDOK_PRAWA,
      },
      {
        x: 0.5, y: 1.1, z: -0.5, scale: 3.7, ratio: 1, url: DRUGI_WIDOK_PRAWA,
      },
      {
        x: 0.5, y: 1.1, z: -0.5, scale: 3.7, ratio: 1, url: TRZECI_WIDOK_PRAWA,
      },
    ],
    left: [
      {
        x: -1, y: -0.8, z: 0.7, scale: 1.8, ratio: 1, url: PIERWSZY_WIDOK_LEWA,
      },
      {
        x: -1, y: -0.6, z: 0.7, scale: 1.8, ratio: 1, url: DRUGI_WIDOK_LEWA,
      },
      {
        x: -1, y: -0.6, z: 0.7, scale: 1.8, ratio: 1, url: TRZECI_WIDOK_LEWA,
      },
    ],
    background: [
      {
        x: -3.4, y: 2.36, z: -0.7, scale: 2.6, ratio: 1, url: bg,
      },
      {
        x: 0.8, y: -0.6, z: -1.5, scale: 2.8, ratio: 1, url: bg,
      },
    ],
    center: [
      {
        x: 0, y: 1.35, z: 0, scale: 4.2, ratio: 1, url: plakatMain,
      },
      {
        x: 0, y: 1.4, z: 0, scale: 5.5, ratio: 1, url: plakat2img,
      },
    ],
    text: [
      {
        x: 0, y: 1.4, z: 0, scale: 4, ratio: 0.75516224,
        text: 'Słysząc słowo DOM zwracamy się przede wszystkim ku swojemu domowi, mieszkaniu. A gdyby tak spojrzeć szerzej i za nasze DOMy uznać, jak to się czasem mówi - cały świat? Więcej informacji w zakładce Program. ',
        items: [
          'kontakt@fomkrakow.pl\n+48 733 779 907',
        ],
      },
    ],
  },
};

export default data;
