import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import _ from 'lodash';
import minutesToHourString from '../../utils/minutesToHourString';
import withDatesQuery from './withDatesQuery';
import Loading from '../../Loading';
import Typography from '../styledElements/Typography';

const conditions = ({ dzielnica, lokalizacja, wydarzenie }) => [
  '{field: "type", operator: EQUAL, value: ["event"]}',
  '{field: "status", operator: EQUAL, value: ["1"]}',
  `{field: "field_event_type", value:["${[lokalizacja && '30', wydarzenie && '29'].join(
    '","',
  )}"]}`,
  Array.isArray(dzielnica) && dzielnica.length > 0
    ? `{field: "field_district", operator: IN, value: ["${dzielnica.join('","')}"]}`
    : '',
].join(',');

const dateConditions = (
  {
    daty = [], godzinaStart = 540, godzinaKoniec = 1260, opis,
  },
  dateOptions,
) => {
  let dates = daty;
  if (
    (!daty || !Array.isArray(daty) || daty.length < 1)
    && (godzinaStart !== '540' || godzinaKoniec !== '1260')
  ) {
    dates = dateOptions.map(date => date.id);
  }
  const start = `${minutesToHourString(godzinaStart)}:00`;
  const end = `${minutesToHourString(godzinaKoniec)}:00`;
  const d = dates
    .map(
      data => `{field: "field_start_dates", value: ["${data}T${start}", "${data}T${end}"],operator: BETWEEN}`,
    )
    .join(',');
  const o = opis
    ? opis
      .split(' ')
      .map(
        word => `{field: "field_description", operator: LIKE, value: ["%${word}%"]},{field: "field_name", operator: LIKE, value: ["%${word}%"]}`,
      )
      .join(',')
    : '';

  return `groups: [{ conjunction: OR, conditions: [${d}] },{ conjunction: OR, conditions: [${o}]}]`;
};

const withQuery = WrappedComponent => ({ filters, dateOptions, ...props }) => {
  const PROGRAM_QUERY = gql`
    query {
      nodeQuery(
        limit: 1000
        sort: {field: "field_name", direction: ASC}
        filter: { conditions: [
          ${conditions(filters)}
        ], 
          ${dateConditions(filters, dateOptions)}
      }
      ) {
        entities {
          ... on NodeEvent {
            id: uuid
            name: fieldName
            description: fieldDescription
            fieldStartDates {
              value
            }
            district: fieldDistrict {
              entity {
                name
              }
            }
            photos: fieldPhoto {
              id: targetId
              url
              alt
            }
            fieldNoRegister
            fieldIsactive
            fieldRegistrationLink{
              uri
            }
            registrationDays: reverseFieldWydarzeniaNode {
              entities {
                ... on NodeDzienRejestracyjny {
                  id: uuid
                  fieldDate {
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
  return (
    <Query query={PROGRAM_QUERY}>
      {({ data, loading }) => {
        if (loading) {
          return (
            <div style={{ margin: '100px 0px 40px 50px' }}>
              <Loading />
            </div>
          );
        }
        if (_.get(data, 'nodeQuery.entities', []).length < 1) {
          return (
            <div style={{ margin: '100px 0px 40px 50px' }}>
              <Typography font="questa" size="medium">
                Nie znaleziono żadnych wyników.
              </Typography>
            </div>
          );
        }
        const events = _.get(data, 'nodeQuery.entities', []).map(event => ({
          ...event,
          dateEnd: _.get(event, 'dateEnd.value', ''),
          dateStart: _.get(event, 'dateStart.value', ''),
          district: _.get(event, 'district.entity.name', ''),
          fieldRegistrationLink: _.get(event, 'fieldRegistrationLink.uri', ''),
          registrationDays: _.get(event, 'registrationDays.entities', []).map(day => ({
            ...day,
            data: day.fieldDate.value,
          })),
        }));

        return <WrappedComponent events={events} {...props} />;
      }}
    </Query>
  );
};

export default WrappedComponent => withDatesQuery(withQuery(WrappedComponent));
