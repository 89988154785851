import React from 'react';
import Typography from '../components/styledElements/Typography';
import './loading.scss';

const Loading = () => (
  <Typography font="questa" size="medium" className="saving">
    Szukam
    <span>.</span>
    <span>.</span>
    <span>.</span>
  </Typography>
);

export default Loading;
