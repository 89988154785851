import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import _ from 'lodash';

const withQuery = WrappedComponent => ({ filters, ...props }) => {
  const PROGRAM_QUERY = gql`
    query {
      nodeQuery(
        filter: { conditions: [{ field: "type", operator: EQUAL, value: ["dzien_rejestracyjny"] }] }
        sort: [{ field: "field_date", direction: ASC }]
        limit: 500
      ) {
        entities {
          ... on NodeDzienRejestracyjny {
            fieldDate {
              date
            }
            fieldWydarzenia {
              entity {
                ... on NodeEvent {
                  fieldName
                  id: uuid
                  fieldDistrict {
                    entity {
                      name
                    }
                  }
                  fieldRegistrationLink {
                    uri
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  return (
    <Query query={PROGRAM_QUERY}>
      {({ data }) => {
        const elemsObj = _.get(data, 'nodeQuery.entities', []).reduce((prev, curr) => {
          const base = prev;
          const cathegory = curr.fieldDate.date.match(/^[0-9-]+/)[0];
          if (Array.isArray(prev[cathegory])) {
            base[cathegory].push(curr);
          } else {
            base[cathegory] = [curr];
          }
          return base;
        }, {});
        const elemsArr = Object.keys(elemsObj)
          .map(key => [key, elemsObj[key]])
          .sort(([a], [b]) => a.localeCompare(b));
        return <WrappedComponent elems={elemsArr} {...props} />;
      }}
    </Query>
  );
};

export default withQuery;
